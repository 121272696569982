import request from '@/utils/request'

// 按分页获取职位等级列表
export function getPositionLevelPage(reqParams) {
  return request({
    url: `/positionLevel/get/page`,
    method: 'get',
    params: reqParams
  })
}

// 通过id获取职位等级
export function getpositionLevel(id) {
  return request({
    url: `/positionLevel/get/${id}`,
    method: 'get'
  })
}

// 新增职位等级
export function addPositionLevel(reqParams) {
  return request({
    url: '/positionLevel/add',
    method: 'post',
    data: reqParams
  })
}

// 删除职位等级
export function deletePositionLevel(id) {
  return request({
    url: `/positionLevel/delete/${id}`,
    method: 'delete'
  })
}

// 编辑职位等级
export function editPositionLevel(param) {
  return request({
    url: `/positionLevel/update`,
    method: 'put',
    data: param
  })
}
