<template>
  <el-dialog
    title="配置"
    :visible="visible"
    :close-on-click-modal="false"
    width="1000px"
    @close="closeVisible"
  >
    <div class="block">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="small"
      >
        <el-form-item label="类型:" prop="type">
          <el-select
            v-model="form.type"
            clearable
            placeholder="请选择"
            filterable
            @change="typeChange"
            :disabled="form.id ? true : false"
          >
            <el-option :value="1" label="用户" />
            <el-option :value="2" label="职位" />
          </el-select>
        </el-form-item>
        <el-form-item v-show="form.type === 1" label="设置用户:" prop="user">
          <el-select
            v-model="form.user"
            multiple
            :remote-method="getUserInfoByNameFn"
            placeholder="请输入后选择"
            filterable
            remote
            reserve-keyword
            clearable
            :disabled="form.id ? true : false"
          >
            <el-option
              v-for="(chanp, userIndex) in userList"
              :label="`${chanp.name}(${chanp.username})`"
              :value="`${chanp.name}-${chanp.userId}`"
              :key="userIndex"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="权限设置:" prop="rightType">
          <el-select
            v-model="form.rightType"
            @change="rightTypeChange"
            clearable
          >
            <el-option :value="1" label="所有账号" />
            <el-option :value="2" label="所负责账号" />
            <el-option :value="3" label="平台+站点" />
          </el-select>
        </el-form-item>

        <el-form-item
          v-show="form.rightType === 3"
          label="设置平台:"
          prop="platform"
        >
          <el-select
            v-model="form.platform"
            placeholder="请选择"
            filterable
            clearable
            @change="platformChange"
          >
            <el-option
              v-for="(chanp, userIndex) in platformList"
              :label="chanp.platform"
              :value="chanp.platform"
              :key="userIndex"
            />
          </el-select>
          站点:
          <el-select
            v-model="form.site"
            multiple
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(chanp, userIndex) in siteList"
              :label="chanp.site"
              :value="chanp.site"
              :key="userIndex"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="form.type === 2"
          label="设置职位:"
          prop="name"
          style="width: 100%"
        >
          <div class="tagDiv">
            <el-tag
              v-for="(tag, index) in tags"
              :key="index"
              size="small"
              type="info"
              @close="handleClose(tag)"
              style="margin: 0 2px"
            >
              {{ tag.upName }}
            </el-tag>
          </div>
          <el-button v-if="!form.id" type="primary" size="small" @click="againSelect"
            >重新选择</el-button
          >

          <div v-if="isOpenTable">
            <el-form :model="searchForm" size="small" ref="searchForm" inline>
              <el-form-item label="职位名称" inline="true">
                <el-input
                  v-model="searchForm.upName"
                  placeholder="职位名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="部门长称">
                <el-input
                  v-model="searchForm.longName"
                  clearable
                  placeholder="部门长称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="
                    pageNum = 1;
                    getPost();
                  "
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <el-table
              :data="tableData"
              size="small"
              border
              width="100%"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
              :row-key="(row) => row.upId"
              :header-cell-style="{ background: '#41A1FF', color: '#fff' }"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
                width="55"
              >
              </el-table-column>
              <!-- <el-table-column
              label="单选"
              fixed="left"
              width="90"
              align="center"
            >
              <template slot-scope="scope">
                <el-radio
                  :label="scope.row.upId"
                  v-model="form.upId"
                  @change="changeRedio($event, scope.row)"
                  >&nbsp;</el-radio
                >
              </template>
            </el-table-column> -->
              <el-table-column
                label="职位id"
                prop="upId"
                align="center"
              ></el-table-column>
              <el-table-column
                label="职位名"
                prop="upName"
                align="center"
              ></el-table-column>
              <el-table-column
                label="职位长称"
                prop="longName"
                align="center"
              ></el-table-column>
            </el-table>

            <div style="text-align: center; margin-top: 1em">
              <el-pagination
                :current-page="pageNum"
                :page-size="pageSize"
                :total="total"
                :page-sizes="[2, 5, 8, 10, 20, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="info" size="small" @click="closeVisible()"
        >取 消</el-button
      >
      <el-button type="primary" size="small" @click="submit('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getUserInfoByName, getUserInfoByIdList } from '@/api/login'
import { getPositionLevelPage } from '@/api/position-level'
import { getPlatformInfo } from '@/api/platformManagement'
import {
  userAccountRightById,
  userAccountRightAdd,
  userAccountRightUpdate
} from '@/api/accountDataPermission'
import { getPostList } from '@/api/post'

export default {
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        platform: '',
        rightType: '',
        site: [],
        type: '',
        uid: '',
        workcode: '',
        upId: ''
      },
      userList: [],
      upList: [],
      platformList: [],
      siteList: [],
      props: {
        value: 'value',
        label: 'label',
        children: 'siteArr',
        multiple: true
      },
      rules: {
        type: [{ required: true, trigger: 'change', message: '请选择' }],
        rightType: [{ required: true, trigger: 'change', message: '请选择' }],
        user: [{ required: false, trigger: 'change', message: '请选择' }],
        platform: [{ required: false, trigger: 'change', message: '请选择' }]
      },
      tableData: [],
      searchForm: {},
      pageNum: 1,
      pageSize: 5,
      total: 0,
      tags: [],
      isOpenTable: true
    }
  },
  watch: {
    visible: function(val, oldval) {
      this.visible = val
      if (val && this.id) {
        this.getList()
      } else {
        this.form = {
          name: '',
          platform: '',
          rightType: '',
          site: [],
          type: '',
          uid: '',
          workcode: '',
          upId: ''
        }

        this.tags = []
        this.isOpenTable = true

        this.tableData = []
      }
    }
  },
  created() {
    this.getPlatfm()
  },
  methods: {
    getList() {
      userAccountRightById(this.id)
        .then((res) => {
          this.form = res.data || {}
          this.userList = []
          this.form.user = []
          this.tags = []
          this.isOpenTable = false

          if (this.form.type === 1) {
            let allIds = []
            if (res.data.name.includes(',')) {
              allIds = res.data.uid.split(',')

              const arr = res.data.uid.split(',')
              const name = res.data.name.split(',')
              arr.forEach((ele, index) => {
                this.form.user.push(name[index] + '-' + ele)
              })
            } else {
              allIds = [res.data.uid]
              this.form.user.push(res.data.name + '-' + res.data.uid)
            }

            getUserInfoByIdList({
              userIds: allIds
            }).then((res) => {
              this.userList = res.data || []
            })
          } else if (this.form.type === 2) {
            if (res.data.name.includes(',')) {
              const upIds = res.data.uid.split(',')
              const upNames = res.data.uid.split(',')
              upIds.forEach((ele, index) => {
                this.tags.push({
                  upName: ele,
                  upId: upNames[index]
                })
              })
            } else {
              this.tags = [{ upName: res.data.name, upId: res.data.uid }]
            }
          } else {
          }

          if (this.form.platform) {
            this.form.site = this.form.site ? res.data.site.split(',') : ''
            const dataSi = this.platformList.find((item) => {
              return item.platform === this.form.platform
            })
            this.siteList = dataSi.siteArr || []
          }
        })
        .catch(() => {
          this.form = {
            name: '',
            platform: '',
            rightType: '',
            site: [],
            type: '',
            uid: '',
            workcode: '',
            upId: ''
          }
        })
    },
    closeVisible(handle) {
      this.$emit('close', handle)

      if (!this.form.id && this.form.type === 2) {
        this.$refs.multipleTable.clearSelection()
      }
    },
    getUserInfoByNameFn(query) {
      if (query !== '') {
        const params = {
          name: query,
          pageNum: 1,
          pageSize: 10
        }
        getUserInfoByName(params).then((res) => {
          this.userList = res.data.list
        })
      }
    },
    getUp() {
      getPositionLevelPage({ pageSize: 10000, pageNum: 1 }).then((res) => {
        this.upList = res.data
      })
    },
    getPlatfm() {
      getPlatformInfo({ pageSize: 10000, pageNum: 1 }).then((res) => {
        this.platformList = res.data || []
      })
    },
    platformChange() {
      this.siteList = []
      this.form.site = []
      const data = this.platformList.find((item) => {
        return item.platform === this.form.platform
      })
      this.siteList = data.siteArr || []
    },
    getPost() {
      const params = {
        ucId: process.env.VUE_APP_COMPANY_ID, // 测试215
        upName: this.searchForm.upName,
        longName: this.searchForm.longName,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }

      getPostList(params)
        .then((res) => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
        .catch(() => {
          this.tableData = []
          this.total = 0
        })
    },
    changeRedio(event, row) {
      this.form.upId = event
      this.tags = [
        {
          upName: row.upName,
          upId: event
        }
      ]
    },
    handleClose(tag) {
      if (this.form.id) {
        this.$message({
          message: '修改职位不能编辑',
          type: 'warning'
        })
      } else {
        this.tags.splice(this.tags.indexOf(tag), 1)
        this.tableData.forEach((row) => {
          if (row.upId === tag.upId) {
            this.$refs.multipleTable.toggleRowSelection(row)
          }
        })
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getPost()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getPost()
    },
    submit() {
      if (this.form.type === 2 && this.tags.length === 0) {
        this.$message({
          message: '请设置职位',
          type: 'error'
        })
        return false
      }

      if (this.form.type === 1) {
        this.rules.user[0].required = true
      } else {
        this.rules.user[0].required = false
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          const name = []
          const userId = []
          const upname = []
          const upId = []

          if (this.form.type === 1) {
            this.form.user.forEach((ele) => {
              name.push(ele.split('-')[0])
              userId.push(ele.split('-')[1])
            })
          } else if (this.form.type === 2) {
            this.tags.forEach((ele) => {
              upname.push(ele.upName)
              upId.push(ele.upId)
            })
          }
          const param = {
            id: this.form.id ? this.form.id : null,
            type: this.form.type,
            name: this.form.type === 2 ? upname.toString() : name.toString(),
            uid: this.form.type === 2 ? upId.toString() : userId.toString(),
            rightType: this.form.rightType,
            platform: this.form.rightType === 3 ? this.form.platform : null,
            site: this.form.rightType === 3 ? this.form.site.toString() : null,
            workcode: this.$store.getters.roles.userInfo.username
          }
          if (this.form.id) {
            userAccountRightUpdate(param)
              .then((res) => {
                if (res.code === '000000') {
                  this.$message({
                    message: '成功',
                    type: 'success'
                  })
                  this.closeVisible('getList')
                }
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            userAccountRightAdd(param)
              .then((res) => {
                if (res.code === '000000') {
                  this.$message({
                    message: '成功',
                    type: 'success'
                  })
                  this.closeVisible('getList')
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        } else {
          return false
        }
      })
    },
    typeChange() {
      this.form.name = null
      if (this.form.type === 1) {
        this.rules.user[0].required = true
        // this.rules.user[0].required = false
      } else {
        this.rules.user[0].required = false
      }
    },
    rightTypeChange() {
      this.form.platform = ''
      this.form.site = []
      if (this.form.rightType === 3) {
        this.rules.platform[0].required = true
        // this.rules.user[0].required = false
      } else {
        this.rules.platform[0].required = false
      }
    },
    handleSelectionChange(val) {
      this.tags = []
      val.forEach((element) => {
        const up = {
          upName: element.upName,
          upId: element.upId
        }
        this.tags.push(up)
      })
    },
    againSelect() {
      this.isOpenTable = true // 因为数据回显时默认勾中有问题，清空重新选择
      this.tags = []
      this.$refs.multipleTable.clearSelection()
    }
  }
}
</script>

<style scope>
.el-checkbox {
  min-width: 160px;
  margin-right: 0;
}

.el-checkbox + .el-checkbox {
  margin: 0;
}
.log {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 30px;
}
.tagDiv {
  border: 1px solid #ddd;
  width: 200px;
  padding: 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px;
  min-height: 32px;
  display: inline-block;
  vertical-align: top;
}
</style>
