import request from '@/utils/request'

// 查询
export function getuserAccountRight(params) {
  return request({
    url: '/userAccountRight/get/page',
    method: 'get',
    params
  })
}

export function userAccountRightLog(params) {
  return request({
    url: '/userAccountRightLog/get/page',
    method: 'get',
    params
  })
}
export function userAccountRightDelete(id) {
  return request({
    url: '/userAccountRight/delete/' + id,
    method: 'delete'
  })
}
export function userAccountRightById(id) {
  return request({
    url: '/userAccountRight/get/' + id,
    method: 'get'
  })
}
export function userAccountRightAdd(data) {
  return request({
    url: '/userAccountRight/add',
    method: 'post',
    data
  })
}
export function userAccountRightUpdate(data) {
  return request({
    url: '/userAccountRight/update',
    method: 'post',
    data
  })
}
