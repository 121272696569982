<template lang="">
  <div style="padding:20px">
    <el-form :model="searchForm" inline size="small">
      <el-form-item label="用户">
        <el-input v-model="searchForm.userName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="职位">
        <el-input v-model="searchForm.positionName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="success" size="small" @click="openEdit({})">新增</el-button>
    </el-form>
    <el-table v-loading="loading" :data="dataList" size="small" border
      :header-cell-style="{background: '#41A1FF', color:'#fff'}" height="calc(100vh - 220px)">
      <el-table-column label="类型" prop="type" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">用户</span>
          <span v-else-if="scope.row.type === 2">职位</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name" align="center"></el-table-column>
      <el-table-column label="权限设置">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.rightType === 1">所有账号</span>
            <span v-else-if="scope.row.rightType === 2">所负责的账号</span>
            <span v-else-if="scope.row.rightType === 3">平台+站点</span>
            <span v-else></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="平台" prop="platform" align="center"></el-table-column>
      <el-table-column label="站点" prop="site" align="center"></el-table-column>
      <el-table-column label="日志" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="openLog(scope.row)">日志</el-button>
        </template>
      </el-table-column>

      <el-table-column label="添加日期" prop="updateTime" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="openEdit(scope.row)">修改</el-button>
          <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div style="text-align:center;margin-top:1em;">
      <el-pagination :current-page="pageNum" :page-size="pageSize" :total="total" :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <log :visible="logVisible" :id="uraId" @close="changeLogVisible" />
    <editDialog :visible="editVisible" :id="editId" @close="changeEditVisible" />
  </div>
</template>

<script>
import {
  getuserAccountRight,
  userAccountRightDelete
} from '@/api/accountDataPermission.js'
import log from './components/log.vue'
import editDialog from './components/editDialog.vue'

export default {
  components: { log, editDialog },
  data() {
    return {
      searchForm: {
        positionName: '',
        userName: ''
      },
      total: 0,
      pageSize: 10,
      pageNum: 1,
      loading: false,
      dataList: [],
      logVisible: false,
      editVisible: false,
      uraId: 0,
      editId: 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.dataList = []
      const param = {
        ...this.searchForm,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getuserAccountRight(param).then((res) => {
        this.dataList = res.data.list || []
        this.total = res.data.total
      }).catch(() => {
        this.total = 0
        this.dataList = []
      })
    },
    search() {
      this.pageNum = 1
      this.getList()
    },
    changeLogVisible(val) {
      this.logVisible = val
    },
    changeEditVisible(val) {
      this.editVisible = false
      if (val) {
        this.getList()
      }
    },
    openLog(row) {
      this.uraId = row.id
      this.logVisible = true
    },
    openEdit(row) {
      this.editId = row.id || null
      this.editVisible = true
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    del(row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userAccountRightDelete(row.id)
            .then((response) => {
              if (response.code === '000000') {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.getList()
              } else {
                this.$message.error('删除失败')
              }
            })
            .catch(() => { })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

  }
}
</script>
<style lang="">
</style>
