<template>
  <el-dialog
    title="查看日志"
    :visible="visible"
    :close-on-click-modal="false"
    width="1000px"
    @close="closeVisible"
  >
    <div class="block">
      <el-table
        :data="list"
        size="small"
        border
        :header-cell-style="{ background: '#41A1FF', color: '#fff' }"
        height="calc(100vh - 220px)"
      >
      <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
        <el-table-column
          label="操作类型"
          prop="actionStr"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作内容"
          prop="actionContent"
          align="center"
        >
         <template slot-scope="scope">
          <span>类型：{{JSON.parse(scope.row.actionContent).type === 1 ? '用户' : '职位' }}，</span>
          <span>名称：{{JSON.parse(scope.row.actionContent).name || '空'  }}，</span>
          <span v-if="JSON.parse(scope.row.actionContent).rightType === 1">权限设置：所有账号</span>
          <span v-else-if="JSON.parse(scope.row.actionContent).rightType === 2">权限设置：所负责账号</span>
          <span v-else-if="JSON.parse(scope.row.actionContent).rightType === 3">权限设置：平台+站点，平台：{{JSON.parse(scope.row.actionContent).platform}}，站点：{{JSON.parse(scope.row.actionContent).site ? JSON.parse(scope.row.actionContent).site : 'all'}}</span>
          <span v-else>权限设置：空</span>
        </template>
        </el-table-column>
        <el-table-column
          label="操作人"
          prop="actionUser"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作时间"
          prop="createTime"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div style="text-align:center;margin-top:1em;">
      <el-pagination :current-page="pageNum" :page-size="pageSize" :total="total" :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="closeVisible('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { userAccountRightLog } from '@/api/accountDataPermission'

export default {
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      pageSize: 10,
      pageNum: 1
    }
  },
  watch: {
    visible: function(val, oldval) {
      this.visible = val
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    getList() {
      const param = {
        uraId: this.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      userAccountRightLog(param)
        .then((res) => {
          this.list = res.data.list || []
          this.total = res.data.total
        })
        .catch(() => {
          this.list = []
          this.total = 0
        })
    },
    closeVisible(formName) {
      this.$emit('close', false)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    }
  }
}
</script>

